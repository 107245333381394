const DPRABI = require('./DPR.abi.json')
const USDCABI = require('./USDC.abi.json')
const DPRUSDC_ABI = require('./DPRUSDC.abi.json')
// const Reward_ABI = require('./Reward.abi.json')
const Staking_ABI = require('./Staking.abi.json')
const IDO_ABI = require('./IDO.abi.json')
const IDO_ABI_TEST = require('./IDO.test.abi.json')
const KOL_ABI_TEST = require('./KOL.test.abi.json')
const KOL_ABI = require('./KOL.abi.json')
const Airdrop_ABI = require('./Airdrop.abi.json')
const Airdrop_ABI_TEST = require('./Airdrop.test.abi.json')
const VC_ABI = require('./VC.abi.json')

module.exports = {
  A: {
    name: "DPR",
    address: "0xf3AE5d769e153Ef72b4e3591aC004E89F48107a1",
    abi: DPRABI
  },
  B: {
    name: 'USDC',
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    abi: USDCABI
  },
  LP: {
    name: 'DPR-USDC LP',
    address: '0x85673c92f0f27a9c4d8c221f6bfefa33b716338a',
    abi: DPRUSDC_ABI
  },
  staking: {
    name: 'staking',
    address: '0x5A5B5955e760bDD16377ad1A41c1862B01d7A1f4',
    abi: Staking_ABI
  },
  ido: {
    name: 'ido',
    address: '0x95827aD2D5946766ec46593c6663CB07Cc78E4f7',
    abi: IDO_ABI
  },
  idoTest: {
    name: 'idoTest',
    address: '0x9Cb80633fBb86D3efb95d456dA7FAEe7b0Eda87e',
    abi: IDO_ABI_TEST
  },
  kol: {
    name: 'kol',
    address: '0x7a007d7714b14dcb9b57796932c23a8cf268e4c1',
    abi: KOL_ABI
  },
  kolTest: {
    name: 'kolTest',
    address: '0x5039a4B513076F8322D2b3D983B355a689710b3c',
    abi: KOL_ABI_TEST
  },
  airdrop: {
    name: 'airdrop',
    address: '0x4A5586E44eB58d9F160b21484963AA5a5F96eF41',
    abi: Airdrop_ABI
  },
  airdropTest: {
    name: 'airdropTest',
    address: '0xD4D0cFC3684D268bD52562Ffca52861de46158E8',
    abi: Airdrop_ABI_TEST
  },
  VC: {
    name: 'VC',
    address: "0x01b13c74203cA9314bd468B5e1dA66329e56b341",
    abi: VC_ABI
  }
}