
export default {
  props: {
    errorList: {
      type: Array
    }
  },
  methods: {
    openBlockHash(hash) {
      window.open(`https://ropsten.etherscan.io/tx/${hash}`)
    },
  }
}
